<template>
  <div
    class="desktopLayoutClass"
    style="z-index: 0;height: 100vh"
  >
    <v-app style="z-index: 0;">
      <div
        v-show="show_header"
        class="header"
        style="
         display: flex;
         flex-direction: row;
         padding-top: 10px;
         padding-left: 5px;"
         id="headerdiv"
      >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToHomePage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt"
              >{{ $i18n.locale == 'en' ? "Video Consultation" : "ဗီဒီယိုဖြင့်တိုင်ပင်ဆွေးနွေးခြင်း" }}</span>
          </div>
        </div>
      </div>
      <!-- Dialog Start -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Unauthorized") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="appLevelCheckModal" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
            <p>{{ $t("Warning_Message.AppUpdatePromptVideoCall") }}</p>
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="closeAppLevelCheckModal">
              {{$t("Warning_Message.Ok")}}
            </v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog
            v-model="appointmentTimeOverModal"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Teleconsultation_Time_Is_Up") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogAppointmentTimeOverModal"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog
            v-model="appointmentStartMessageModal"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title style="word-break:normal">
                {{
                  $t(
                    "Warning_Message.Please_Join5Minutes_Before_Appointment_Time"
                  )
                }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogAppointmentStartMessageModal"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                {{ $t("Warning_Message.Something_Went_Wrong") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <!-- Dialog Close -->
      <div v-if="executeSecondTimer">
        <!-- <p style="color:red;border:2px;" class="timerClass">
          {{ countDownTimer }}
        </p> -->
        <v-row>
          <v-col cols="12" sm="12" style="padding: 0px;">
            <!-- <iframe
   :src="appointment_url"
   allow="camera; microphone; fullscreen; speaker; display-capture" style="overflow:hidden;height:90vh;width:100%"
   ></iframe> -->
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <div>
          <v-row> </v-row>
          <v-row>
            <v-col cols="12" sm="12" style="padding: 0px; height: 89vh;">
              <!-- <div class="timerClass" style="left:5%; z-index:999">
                <p class="timmerText">{{ countDownTimer }}</p>
              </div> -->
              <div id="video-container" class="row remote_video_container">
              </div>

              <div
                style="display:flex; flex-direction: row;justify-content: space-evenly; margin : 0px 20px 0px 0px !important "
                v-if="callConnected"
                >
                <div style="margin:2px" v-if="deviceIDList.length > 1">
                  <!--  -->
                  <v-btn
                    elevation="8"
                    :disabled="isCameraSwitched"
                    v-if="videoBtnFlag"
                    style=" background: #FFFFFF;border-radius: 8px; color:white;position: fixed; bottom: 0;margin-bottom: 30px;"
                    @click="switchCamera(activeRoom)"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/switchCamera.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    v-if="videoBtnFlag"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;position: fixed; bottom: 0;margin-bottom: 30px;"
                    @click="turnVideoOff"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/CameraOn.svg"
                  /></v-btn>
                  <v-btn
                    elevation="8"
                    v-else
                    style="  background: #FFFFFF;border-radius: 8px; color:white;position: fixed; bottom: 0;margin-bottom: 30px;"
                    @click="turnVideoOn"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/CameraOff.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    v-if="audioBtnFlag"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;position: fixed; bottom: 0;margin-bottom: 30px;"
                    @click="turnAudioOff"
                    id="muteAudio"
                    icon
                    ><img src="https://s3iconimages.mymedicine.com.mm/micOn.svg"
                  /></v-btn>
                  <v-btn
                    elevation="8"
                    v-else
                    style="  background: #FFFFFF;border-radius: 8px; color:white;position: fixed; bottom: 0;margin-bottom: 30px;"
                    @click="turnAudioOn"
                    icon
                    ><img src="https://s3iconimages.mymedicine.com.mm/micOff.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    id="disconnectBtn"
                    style="background: #EB5757; border-radius: 8px; position: fixed; bottom: 0; margin-bottom: 30px;"
                    @click="endTwilioVideoCall"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/leaveCall.svg"
                  /></v-btn>
                </div>
                <!-- <div>
                  <v-btn
                    elevation="8"
                    id="disconnectBtn"
                    style="background: #EB5757; border-radius: 8px; position: fixed; bottom: 0; margin-bottom: 30px;color:white"
                    @click= "increaseHeight"
                    icon
                    > +</v-btn>
                </div> -->
              </div>
              <div v-else-if="!callConnected && waitTimer == false" style="position: fixed; bottom: 0; margin-bottom: 30px;">
                <div align="center">
                  <p style="padding: 10px 20px; line-height: 35px">{{ $t("Warning_Message.No_Connection") }}</p>
                </div>
                <div style="margin:2px">
                  <v-btn
                    elevation="8"
                    id="disconnectBtn"
                    @click="refreshPage"
                    class="warning"
                    style="width: fit-content; padding: 20px; border-radius: 8px; color: white"
                    icon
                    ><img
                      src="https://s3iconimages.mymedicine.com.mm/video_refresh.svg"
                  /> {{ $t("Warning_Message.Reload") }}</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
const Twilio = require("twilio-video");
export default {
  name: "CustomerVideoCall",
  components: {
    NavBar,
  },
  data() {
    return {
      auth_error_enable: false,
      error_enable: false,
      // booking_id:"",
      currentCustomer: "",
      customer_name: "",
      customer_id: "",
      appointment_id: "",
      appointment_url: "",
      countDownTimer: "",
      timerValue: "",
      appointmentObject: "",
      clearDistance: false,
      executeSecondTimer: false,
      isMobile: false,
      appointmentTimeOverModal: false,
      showTimer: false,
      appointmentStartMessageModal: false,
      isLoading: true,
      sessionId: "",
      sessionToken: "",
      publisherData: "",
      videoStatus: true,
      audioStatus: true,
      videoBtnFlag: true,
      audioBtnFlag: true,
      endcall: false,
      session: "",
      promiseFun: "",
      show_header:true,
      sessionLocalDetails:"",
      appLevelCheck: true,
      appLevelCheckModal: false,
      twilioMeetLink: null,
      twilioRoomName: null,
      container: null,
      activeRoom: null,
      videoConstraint:"",
      deviceID:"",
      deviceIDList:[],
      deviceIndex:0,
      videoHeight:0,
      callConnected:false,
      currentCamIndex: 0,
      waitTimer: true,
      remoteParticipant:null,
      ConnectTrack:"",
      currentFacingMode:"user",
      isCameraSwitched:false,
    };
  },
  methods: {
    refreshPage()
    {
      this.$router.go();
    },
    increaseHeight(){
      this.videoHeight = this.videoHeight + 50
    },
    endTwilioVideoCall()
    {
      if(this.activeRoom)
      {
        this.activeRoom.disconnect();
        this.logEvent("leave");
      }
    },
    async startRoom(){
        const room = await this.joinVideoRoom(this.twilioRoomName, this.twilioMeetLink);
        this.activeRoom = room;
        this.handleConnectedParticipant(room.localParticipant,"local");
        room.participants.forEach(this.handleConnectedParticipant);
        room.on("participantConnected", this.handleConnectedParticipant);
        room.on("participantDisconnected", this.handleDisconnectedParticipant);
        room.on('disconnected', room => {
          // Detach the local media elements
          room.localParticipant.tracks.forEach(publication => {
            publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
          });
        });
        this.callConnected = true;
        navigator.mediaDevices.enumerateDevices().then((cams) =>{
          cams.forEach(mediaDevice => {
            if (mediaDevice.kind === 'videoinput') {
              this.deviceIDList.push({name:mediaDevice.label, deviceID: mediaDevice.deviceId})
            }
          })
        });
        // console.log("this.deviceIDList",this.deviceIDList.length)
        this.deviceIDList.sort();
        this.joinLogEvent("join");
        // room.participants.forEach(participant => {
        // participant.tracks.forEach(publication => {
        // if (publication.track) {
        // document.getElementById('remote-media-div').appendChild(publication.track.attach());

        // publication.track.on('enabled', () => {
        // this.audioBtnFlag = true
        // });
        // }
        // });

        // participant.on('trackSubscribed', track => {
        // document.getElementById('remote-media-div').appendChild(track.attach());

        // track.on('enabled', () => {
        // // do something with the UI here
        // this.audioBtnFlag = false
        // });
        // });
        // });
        window.addEventListener("pagehide", () =>
          {
            room.disconnect();
          });
        window.addEventListener("beforeunload", () =>
          {
            room.disconnect();
          });
    },
    handleConnectedParticipant(participant, type){
        const participantDiv = document.createElement("div");
        participantDiv.setAttribute("id", participant.identity);
        if(type != "local"){
          this.remoteParticipant = participant
          participantDiv.setAttribute("style", " width: 100% !important;height: 50%");
        }
        else{
          participantDiv.setAttribute("style", " width: 100% !important;height: 50%");
        }
       // participantDiv.setAttribute("style", "height: 50vh !important; width: 100% !important;");
        this.container.prepend(participantDiv);

        participant.tracks.forEach((trackPublication) => {
            this.handleTrackPublication(trackPublication, participant,type);
            // console.log("trackPublication",trackPublication)
        });
        participant.on("trackPublished", this.handleTrackPublication);
    },
    handleTrackPublication(trackPublication, participant, type){
      if(!participant)
      {
        participant = this.remoteParticipant

        const CurrentparticipantDiv = document.getElementById(participant.identity);
        // CurrentparticipantDiv.remove();
        var children = CurrentparticipantDiv.children;
        for (var i = 0; i < children.length; i++) {
          var tableChild = children[i];
          if(tableChild.tagName == 'VIDEO')
          {
            tableChild.remove();
          }
        }

        var switchCamPerson = true;
        participant = this.activeRoom.localParticipant;
        let participantDiv ;
        // participantDiv.setAttribute("id", participant.identity);
        if(type != "local"){

        const participantDiv = document.createElement("div");
        participantDiv.setAttribute("id", participant.identity);
          participantDiv.setAttribute("style", " width: 100% !important; height: 50% !important; ");

        }
        else{
          participantDiv.setAttribute("style", " width: 100% !important; height: 50% !important");
            const CurrentparticipantDiv = document.getElementById(participant.identity);
        // CurrentparticipantDiv.remove();
        var children = CurrentparticipantDiv.children;
        for (var i = 0; i < children.length; i++) {
          var tableChild = children[i];
          if(tableChild.tagName == 'VIDEO')
          {
            tableChild.remove();
          }
        }
        const participantDiv = document.createElement("div");
        participantDiv.setAttribute("id", participant.identity);
        }
        this.container.append(participantDiv);
      }
      else
      {
        var switchCamPerson = false;
      }
      function displayTrack(track, type){
        let headerHeight = document.getElementById('headerdiv').clientHeight;
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        var height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
        height = (height - headerHeight)/2
        const participantDiv = document.getElementById(participant.identity);
        participantDiv.style.transform = "scale(-1,1)"
        participantDiv.style.width = width + 'px';
        participantDiv.style.height = height + 'px';
        participantDiv.append(track.attach());
        if(type != "local")
        {
           for(var i = 0; i < participantDiv.childElementCount; i++)
            {
              if(participantDiv.children[i].tagName == 'VIDEO')
              {
                  participantDiv.children[i].style.height = "100%";
                  participantDiv.children[i].style.width = "100%";
                  participantDiv.children[i].style.objectFit = 'fill';
              }
            }
        }
        else{
          for(var i = 0; i < participantDiv.childElementCount; i++)
            {
              if(participantDiv.children[i].tagName == 'VIDEO')
              {
                participantDiv.children[i].style.width = "100%";
                participantDiv.children[i].style.height = "100%";
                participantDiv.children[i].style.objectFit = 'cover';
              }
            }
        }
      }
      if (trackPublication.track) {
          displayTrack(trackPublication.track, type);
      }
      trackPublication.on("subscribed", displayTrack);
    },
    handleDisconnectedParticipant(participant){
        participant.removeAllListeners();
        // console.log("in handleDisconnectedParticipant");
        const participantDiv = document.getElementById(participant.identity);
        participantDiv.remove();
    },
    async joinVideoRoom(roomName, token){
      this.videoConstraint ={
        facingMode: this.currentFacingMode
      };
      const tracks = await Twilio.createLocalTracks({
        audio: true,
        video: this.videoConstraint,
        name: this.customer_name
      });
      this.ConnectTrack = tracks
      const room = await Twilio.connect(token, { room: roomName, tracks});
      return room;
    },
    closeAppLevelCheckModal(){
      this.appLevelCheckModal=false
       this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    logEvent(event) {
      if (event == "leave") {
        var leaveLogEventBody = {
          bookingId: this.appointment_id,
          userType: "CUSTOMER",
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
        };
        // console.log("[leaveLogEventBody]",leaveLogEventBody);
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/exitCallLogEntry",
            leaveLogEventBody
          )
          .then((exitCallLogEntryResponse) => {
            this.$router.push({
              name: "Feedback"
            });
            // this.activeRoom.disconnect();
          })
          .catch((exitCallLogEntryError) => {
            console.log("[Exit Call Event error]", exitCallLogEntryError);
          });
      }
    },
    joinLogEvent(event) {
      if (event === "join") {
        var JoinLogEventBody = {
          bookingId: this.appointment_id,
          userType: "CUSTOMER",
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
        };
        // console.log("[Join Log event]", JoinLogEventBody);
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/joinCallEntry",
            JoinLogEventBody
          )
          .then((joinCallEntryResponse) => {
          })
          .catch((joinCallEntryError) => {
            console.log("[Join Call Event Error]", joinCallEntryError);
          });
      }
    },
    sleep(booking_id, token, router) {
      setTimeout(function() {
        this.endTwilioVideoCall();
      }.bind(this), 5000);
    },
    redirectToHomePage() {
      this.$router.go(-1);
    },
    closeDialogAppointmentStartMessageModal() {
      this.$router.push({
        name: "CustomerHomeScreen",
      });
    },
    closeDialogAppointmentTimeOverModal() {

      this.appointmentTimeOverModal = false;
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
    },
    timerOne() {
      var startTime = new Date(
        this.appointmentObject.booking_epoch_time
      ).getTime();
      var currentTime = new Date().getTime();
      if (startTime - currentTime > 600000) {
        this.showTimer = true;
        // this.countDownTimer = "";
      }
      if (this.showTimer) {
        var countDownDate = new Date(this.appointmentObject.booking_closing_epoch_time).getTime();
        var today = new Date(this.appointmentObject.current_epoch_time).getTime();
        var intervalOne = setInterval(() => {
          today = today + 1000
          var distance = countDownDate - today;
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          this.countDownTimer = String(minutes).padStart(2,0) + ":" + String(seconds).padStart(2,0);
          if (distance < 0) {
            clearInterval(intervalOne);
            this.countDownTimer = "00:00";
            this.appointmentTimeOverModal = true;
            this.sleep(this.appointment_id, this.currentCustomer, this.$router);
            // this.timerTwo();
          }
        }, 1000);
      } else {
        this.appointmentStartMessageModal = true;
      }
    },
    timerTwo() {
      // this.executeSecondTimer = true;
      var endTime = this.appointmentObject.time_slot.split("-")[1];
      var bufferTime = Number(endTime.split(":")[1]) + 3;
      var combinedTime = endTime.split(":")[0] + ":" + bufferTime;
      var countDownDate = new Date(
        this.appointmentObject.date_of_appointment +
          " " +
          String(combinedTime) +
          ":00 GMT+0630"
      ).getTime();
      var today = new Date().getTime();
      var distance = countDownDate - today;
      var intervalTwo = setInterval(() => {
        distance = distance - 1000;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.countDownTimer = minutes + ":" + seconds;
        if (distance < 0) {
          clearInterval(intervalTwo);
          this.countDownTimer = "00:00";
          this.appointmentTimeOverModal = true;
          this.executeSecondTimer = false;
          this.endVideoCall()
        }
      }, 1000);
    },
    handleError(error) {
      if (error) {
        alert(error.message);
      }
    },
    turnVideoOff() {
      this.activeRoom.localParticipant.videoTracks.forEach(track => {
      track.track.disable();
      this.videoBtnFlag =  false;
      });
    },
    turnVideoOn(){
      this.activeRoom.localParticipant.videoTracks.forEach(track => {
      track.track.enable();
      this.videoBtnFlag =  true;
      });
    },
    turnAudioOff() {
      //const unmuteAudio = document.getElementById('unmuteAudio');
      this.activeRoom.localParticipant.audioTracks.forEach(track => {
      track.track.disable();
      this.audioBtnFlag =  false;
      });
    },
    turnAudioOn() {
      //const unmuteAudio = document.getElementById('unmuteAudio');
      this.activeRoom.localParticipant.audioTracks.forEach(track => {
      track.track.enable();
      this.audioBtnFlag = true;
      });
    },
    async switchCamera(room) {
      this.isCameraSwitched = true
      const cameraTrack = this.ConnectTrack.find(track => track.kind === 'video');
      this.currentFacingMode = this.currentFacingMode === "user" ? "environment" : "user"
      cameraTrack.restart({ facingMode: this.currentFacingMode });
      this.isCameraSwitched = false

    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'videoCall',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'videoCall',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
  beforeRouteLeave: function(to, from, next) {
    this.activeRoom.disconnect();

    next();
  },
  mounted() {
    this.waitTimer = setTimeout(function(){
      if(!this.callConnected)
      {
        clearTimeout(this.waitTimer);
        this.waitTimer = false;
      }
    }.bind(this), 10000);
    var userAgent = navigator.userAgent
    // var userAgent = "Mozilla/5.0 (Linux; Android 9; MI 6X Build/PKQ1.180904.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.111 Mobile Safari/537.36; kbzpay app/customer platform/android lang/my unicode/true version/5.0.3"
    let screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.videoHeight = (screenHeight/100)*60
    this.$i18n.locale =  this.$store.state.locale;
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    //document.title = "Video Consultation";
    if(this.$store.state.locale == "en")
      document.title = "Video Consultation"
    else
      document.title = "ဗီဒီယိုဖြင့်တိုင်ပင်ဆွေးနွေးခြင်း";

    if(userAgent.includes("kbzpay")){
      var slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/')
      if(slicedData[slicedData.length-1] < "5.2.3"){
       this.appLevelCheck = false
       this.appLevelCheckModal = true
      }
      else
      {
        this.executeSecondTimer = false;
        this.currentCustomer = this.$cookies.get("customerToken");
        var access = this.$cookies.get("access");
        if (!this.currentCustomer) {
          this.$router.push({
            name: "Login",
          });
        } else {
          //logging page hit - start
          let x =  localStorage.getItem("currentURL")
          let pageName = "VideoCallScreen";
          if( x == pageName)
            x = "";
          localStorage.setItem("currentURL", pageName)
          var pageAccessBody = {
            token: this.currentCustomer,
            pageName: pageName,
            previousPage: x ? x : "START"
          }
          axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
          .then((logSuccess) =>{

          })
          .catch((logError) =>{

          });
          //logging page hit - end
          this.appointment_id = this.$route.params.booking_id;
          var viewParticularAppointmentPageData = {
            token: this.currentCustomer,
            bookingId: this.appointment_id,
            typeOfUser: "CUSTOMER",
          };
          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/videoCallPageTwilio",
              viewParticularAppointmentPageData
            )
            .then((viewParticularAppointmentPageResponse) => {
              this.userPageViewTable();
              this.customer_id = viewParticularAppointmentPageResponse.data.customer._id;
              this.customer_name = viewParticularAppointmentPageResponse.data.customer.customer_name;
              this.appointmentObject = viewParticularAppointmentPageResponse.data.data;
              this.twilioMeetLink =this.appointmentObject.twilio_token;
              this.twilioRoomName = this.appointmentObject.twilio_room_name;
              if(this.twilioMeetLink && this.twilioRoomName)
              {
                this.container = document.getElementById("video-container");
                this.startRoom();
              }
              this.isLoading = false;
              // User config call removed.
              // var user_config_body = {
              //   appointment_reference_number: this.appointmentObject.videoCallPage,
              //   appointment_id: this.appointmentObject._id,
              //   meeting_link: this.appointmentObject.meeting_link,
              //   config_type: "VIDEO_CALL",
              //   user_type: "CUSTOMER",
              //   user_name: this.customer_name,
              // };
              // axios
              //   .post(
              //     process.env.VUE_APP_BACKEND_URL + "/createUserConfigTokBox",
              //     user_config_body
              //   )
              //   .then((createUserConfigResponse) => {
              //   })
              //   .catch((createuserconfigError) => {
              //     console.log(createuserconfigError);
              //   });
            })
            .catch((viewParticularAppointmentPageError) => {
              console.log(viewParticularAppointmentPageError);
              if (viewParticularAppointmentPageError.response.status == 401) {
                this.auth_error_enable = true;
              } else {
                this.error_enable = true;
              }
            });
        }
      }
    }
    else
    {
      this.executeSecondTimer = false;
      this.currentCustomer = this.$cookies.get("customerToken");
      var access = this.$cookies.get("access");
      if (!this.currentCustomer) {
        this.$router.push({
          name: "Login",
        });
      } else {
        //logging page hit - start
        let x =  localStorage.getItem("currentURL")
        let pageName = "VideoCallScreen";
        if( x == pageName)
          x = "";
        localStorage.setItem("currentURL", pageName)
        var pageAccessBody = {
          token: this.currentCustomer,
          pageName: pageName,
          previousPage: x ? x : "START"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody)
        .then((logSuccess) =>{

        })
        .catch((logError) =>{

        });
        //logging page hit - end
        this.appointment_id = this.$route.params.booking_id;
        // console.log(this.appointment_id);
        var viewParticularAppointmentPageData = {
          token: this.currentCustomer,
          bookingId: this.appointment_id,
          typeOfUser: "CUSTOMER",
        };
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/videoCallPageTwilio",
            viewParticularAppointmentPageData
          )
          .then((viewParticularAppointmentPageResponse) => {
            this.customer_id =
              viewParticularAppointmentPageResponse.data.customer._id;
            this.customer_name =
              viewParticularAppointmentPageResponse.data.customer.customer_name;
            this.appointmentObject =
              viewParticularAppointmentPageResponse.data.data;
            this.twilioMeetLink =this.appointmentObject.twilio_token;
            this.twilioRoomName = this.appointmentObject.twilio_room_name;
            this.userPageViewTable();
            if(this.twilioMeetLink && this.twilioRoomName)
            {
              this.container = document.getElementById("video-container");
              this.startRoom();
            }
            else
            {
              // console.log(this.appointmentObject
            }
            this.isLoading = false;

            // User config call removed.
            // var user_config_body = {
            //   appointment_reference_number: this.appointmentObject.videoCallPage,
            //   appointment_id: this.appointmentObject._id,
            //   meeting_link: this.appointmentObject.meeting_link,
            //   config_type: "VIDEO_CALL",
            //   user_type: "CUSTOMER",
            //   userId: this.customer_id,
            //   user_name: this.customer_name,
            // };
            // axios
            //   .post(
            //     process.env.VUE_APP_BACKEND_URL + "/createUserConfigTokBox",
            //     user_config_body
            //   )
            //   .then((createUserConfigResponse) => {
            //   })
            //   .catch((createuserconfigError) => {
            //     console.log(createuserconfigError);
            //   });
          })
          .catch((viewParticularAppointmentPageError) => {
            console.log(viewParticularAppointmentPageError);
            if (viewParticularAppointmentPageError.response.status == 401) {
              this.auth_error_enable = true;
            } else {
              this.error_enable = true;
            }
          });
      }
    }
  },
};
</script>

<style scoped>
.timerClass {
  position: absolute;
  margin-top: 20px;
  font-size: 150%;
}
whereby-embed {
  overflow: hidden;
  height: 90vh;
  width: 100%;
}
.pub_class {
  position: absolute;
  z-index: 999;
  bottom: 10%;
  right: 2%;
  margin: 5%;
}
.sub_class {
  position: relative;
  z-index: 0;
  width: 95%;
  height: 80vh;
  margin: 5% 5%;
}
.timmerText {
  position: absolute;
  border: 2px;
  background-color: #010101;
  color: white;
  padding: 5px;
  z-index: 999;
  opacity: 0.5;
  border-radius: 0px 0px 0px 4px;
  font-size: 20px;
}
.remote_video_container {
  left: 0;
  margin: 0;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  /* overflow: scroll !important */
  z-index: -1;
  position: absolute;
  padding-top:  20px ;
}
#localTrack video {
  width: 425px !important;
  background-repeat: no-repeat;
  height: 400px;

}
.EndCallbtnClass{
    position: absolute;
    bottom: 20px;
    display: flex;
    right: 125px;
    margin: 2px;
}

</style>
